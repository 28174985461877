/* eslint-disable react-hooks/exhaustive-deps */
import { NavigationBlockerModalProps } from "@components/modals/NavigationBlockerModal";
import { useNavigationBlockerStore } from "@store/useNavigationBlockerStore";
import { useCallback } from "react";

const useNavigationBlockerModalProps = (): NavigationBlockerModalProps => {
  const {
    navigationBlocked,
    onLeaveCallback,
    setNavigationStoreValues,
    resetNavigationStoreValues,
  } = useNavigationBlockerStore();

  const onLeave = useCallback(() => {
    resetNavigationStoreValues();

    if (onLeaveCallback) {
      onLeaveCallback();
    }
  }, [onLeaveCallback]);

  const onStay = useCallback(() => {
    setNavigationStoreValues({ onLeaveCallback: null });
  }, []);

  const onChangeBlockStatus = useCallback((modalOpened: boolean) => {
    const callbackPlug = () => undefined;

    setNavigationStoreValues({
      onLeaveCallback: modalOpened ? callbackPlug : null,
    });
  }, []);

  return {
    navigationBlocked,
    onChangeBlockStatus,
    onLeave,
    onStay,
    open: !!onLeaveCallback,
  };
};

export { useNavigationBlockerModalProps };
