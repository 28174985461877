import Alert, { AlertColor } from "@mui/material/Alert";
import { SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { FC, forwardRef, RefObject } from "react";

type Props = {
  key: SnackbarKey;
  message: SnackbarMessage;
  severity: AlertColor;
};

// eslint-disable-next-line react/display-name
const AlertMessage: FC<Props> = forwardRef(
  ({ key, message, severity }: Props, ref) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <Alert
        ref={ref as RefObject<HTMLDivElement>}
        variant="filled"
        severity={severity}
        onClose={() => closeSnackbar(key)}
        sx={{
          alignItems: "center",
          "& .MuiAlert-message": {
            pt: 1.5,
          },
        }}>
        {message}
      </Alert>
    );
  }
);

const useSnackbarMessage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbarMessage = (message: string, severity: AlertColor) => {
    enqueueSnackbar(message, {
      content: (key, message) => (
        <AlertMessage key={key} message={message} severity={severity} />
      ),
    });
  };

  return { showSnackbarMessage };
};

export { useSnackbarMessage };
