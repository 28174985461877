import { AppRoute } from "@enums/FE/AppRoute";
import { Role } from "@enums/FE/Role";
import { RouteName } from "@enums/FE/RouteName";
import Auth from "@modules/Auth";
import AccountBalanceTwoTone from "@mui/icons-material/AccountBalanceTwoTone";
import AutoAwesomeMosaicTwoTone from "@mui/icons-material/AutoAwesomeMosaicTwoTone";
import FolderOpenTwoTone from "@mui/icons-material/FolderOpenTwoTone";
import SettingsApplicationsTwoTone from "@mui/icons-material/SettingsApplicationsTwoTone";
import SettingsTwoTone from "@mui/icons-material/SettingsTwoTone";
import TextSnippetTwoTone from "@mui/icons-material/TextSnippetTwoTone";
import TodayTwoTone from "@mui/icons-material/TodayTwoTone";
import { lazy } from "react-lazy-no-flicker";

const BillingBatchDetails = lazy(
  () => import("@modules/Billing/BillingBatches/BillingBatchDetails"),
  { time_before_fallback: 1000 }
);
const BillingBatches = lazy(
  () => import("@modules/Billing/BillingBatches/BillingBatches"),
  { time_before_fallback: 1000 }
);
const PaymentBatchDetails = lazy(
  () => import("@modules/Billing/PaymentBatches/PaymentBatchDetails"),
  { time_before_fallback: 1000 }
);
const PaymentBatches = lazy(
  () => import("@modules/Billing/PaymentBatches/PaymentBatches"),
  { time_before_fallback: 1000 }
);
const ClientManagement = lazy(() => import("@modules/ClientManagement"), {
  time_before_fallback: 1000,
});
const Dashboard = lazy(() => import("@modules/Dashboard"), {
  time_before_fallback: 1000,
});
const DeliveryServicePartners = lazy(
  () => import("@modules/DeliveryServicePartners"),
  { time_before_fallback: 1000 }
);
const HubLocations = lazy(() => import("@modules/HubLocations"), {
  time_before_fallback: 1000,
});
const MappingManagement = lazy(() => import("@modules/MappingManagement"), {
  time_before_fallback: 1000,
});
const BatchOrdersMapping = lazy(
  () => import("@modules/MappingManagement/BatchOrdersMapping"),
  { time_before_fallback: 1000 }
);
const BillingExport = lazy(
  () => import("@modules/MappingManagement/BillingExport"),
  { time_before_fallback: 1000 }
);
const BatchUpload = lazy(() => import("@modules/NewOrder/BatchUpload"), {
  time_before_fallback: 1000,
});
const SingleOrder = lazy(() => import("@modules/NewOrder/SingleOrder"), {
  time_before_fallback: 1000,
});
const OrderDetails = lazy(() => import("@modules/OrderDetails"), {
  time_before_fallback: 1000,
});
const Orders = lazy(() => import("@modules/Orders"), {
  time_before_fallback: 1000,
});
const AllOrders = lazy(() => import("@modules/Orders/AllOrders"), {
  time_before_fallback: 1000,
});
const BatchUploaded = lazy(
  () => import("@modules/Orders/BatchOrders/BatchUploaded"),
  { time_before_fallback: 1000 }
);
const ListBatchOrders = lazy(
  () => import("@modules/Orders/BatchOrders/ListBatchOrders"),
  { time_before_fallback: 1000 }
);
const RatePlanManagement = lazy(() => import("@modules/RatePlanManagement"), {
  time_before_fallback: 1000,
});
const Reminders = lazy(() => import("@modules/Reminders"), {
  time_before_fallback: 1000,
});
const Reports = lazy(() => import("@modules/Reports"), {
  time_before_fallback: 1000,
});
const Report = lazy(() => import("@modules/Reports/Report"), {
  time_before_fallback: 1000,
});
const SearchPage = lazy(() => import("@modules/SearchPage"), {
  time_before_fallback: 1000,
});
const ServiceCatalog = lazy(() => import("@modules/ServiceCatalog"), {
  time_before_fallback: 1000,
});
const SupportingDocs = lazy(() => import("@modules/SupportingDocs"), {
  time_before_fallback: 1000,
});
const TestingTools = lazy(() => import("@modules/TestingTools"), {
  time_before_fallback: 1000,
});
const UserManagement = lazy(() => import("@modules/UserManagement"), {
  time_before_fallback: 1000,
});
const ServiceLevelAgreements = lazy(
  () => import("@modules/ServiceLevelAgreements"),
  { time_before_fallback: 1000 }
);

const allRoles = [
  Role.CSM,
  Role.Ops,
  Role.QA,
  Role.FleetAdmin,
  Role.Billing,
  Role.MS,
  Role.Claim,
];

export const routes = [
  {
    path: AppRoute.Auth,
    public: true,
    element: <Auth />,
  },
  {
    name: RouteName.Dashboard,
    path: AppRoute.Dashboard,
    icon: <AutoAwesomeMosaicTwoTone />,
    element: <Dashboard />,
    roles: allRoles,
  },
  {
    name: RouteName.Orders,
    path: AppRoute.Orders,
    icon: <FolderOpenTwoTone />,
    element: <Orders />,
    roles: allRoles,
    nestedRoutes: [
      {
        path: AppRoute.AllOrders,
        element: <AllOrders />,
      },
      {
        path: AppRoute.BatchUploaded,
        element: <BatchUploaded />,
      },
    ],
  },
  {
    path: AppRoute.OrderDetails,
    element: <OrderDetails />,
  },
  {
    path: AppRoute.BatchOrders,
    element: <ListBatchOrders />,
  },
  {
    path: AppRoute.CreateOrderSingle,
    element: <SingleOrder />,
  },
  {
    path: AppRoute.CreateOrderBatch,
    element: <BatchUpload />,
  },
  {
    name: RouteName.Reports,
    path: AppRoute.Reports,
    icon: <TextSnippetTwoTone />,
    element: <Reports />,
    roles: allRoles,
  },
  {
    path: AppRoute.ReportDetails,
    element: <Report />,
  },
  {
    name: RouteName.Reminders,
    path: AppRoute.Reminders,
    icon: <TodayTwoTone />,
    element: <Reminders />,
    roles: allRoles,
  },
  {
    name: RouteName.Billing,
    icon: <AccountBalanceTwoTone />,
    roles: allRoles,
  },
  {
    name: RouteName.BillingBatches,
    path: AppRoute.BillingBatches,
    element: <BillingBatches />,
    roles: allRoles,
    billing: true,
  },
  {
    path: AppRoute.BillingBatchDetails,
    element: <BillingBatchDetails />,
    roles: allRoles,
  },
  {
    name: RouteName.PaymentBatches,
    path: AppRoute.PaymentBatches,
    element: <PaymentBatches />,
    roles: allRoles,
    billing: true,
  },
  {
    path: AppRoute.PaymentBatchDetails,
    element: <PaymentBatchDetails />,
    roles: allRoles,
  },
  {
    name: RouteName.FleetSettings,
    icon: <SettingsApplicationsTwoTone />,
    roles: allRoles,
  },
  {
    name: RouteName.ServiceCatalog,
    path: AppRoute.ServiceCatalog,
    element: <ServiceCatalog />,
    roles: allRoles,
    fleetSettings: true,
  },
  {
    name: RouteName.RatePlanManagement,
    path: AppRoute.RatePlanManagement,
    element: <RatePlanManagement />,
    roles: allRoles,
    fleetSettings: true,
  },
  {
    name: RouteName.ClientManagement,
    path: AppRoute.ClientManagement,
    element: <ClientManagement />,
    roles: allRoles,
    fleetSettings: true,
  },
  {
    name: RouteName.DSPs,
    path: AppRoute.DSPs,
    element: <DeliveryServicePartners />,
    roles: allRoles,
    fleetSettings: true,
  },
  {
    name: RouteName.HubLocations,
    path: AppRoute.HubLocations,
    element: <HubLocations />,
    roles: allRoles,
    fleetSettings: true,
  },
  {
    name: RouteName.FMSSettings,
    icon: <SettingsTwoTone />,
    roles: allRoles,
  },
  {
    name: RouteName.Users,
    path: AppRoute.Users,
    element: <UserManagement />,
    roles: [Role.CSM, Role.Ops, Role.QA, Role.Billing, Role.MS],
    fmsSettings: true,
  },
  {
    name: RouteName.UserManagement,
    path: AppRoute.UserManagement,
    element: <UserManagement />,
    roles: [Role.FleetAdmin],
    fmsSettings: true,
  },
  {
    name: RouteName.SupportingDocs,
    path: AppRoute.SupportingDocs,
    element: <SupportingDocs />,
    roles: [Role.FleetAdmin],
    fmsSettings: true,
  },
  {
    name: RouteName.TestingTools,
    path: AppRoute.TestingTools,
    element: <TestingTools />,
    roles: allRoles,
    fmsSettings: true,
  },
  {
    name: RouteName.MappingManagement,
    path: AppRoute.MappingManagement,
    element: <MappingManagement />,
    roles: [Role.FleetAdmin, Role.Billing],
    fmsSettings: true,
    nestedRoutes: [
      {
        path: AppRoute.BillingExport,
        element: <BillingExport />,
      },
      {
        path: AppRoute.BatchOrderMapping,
        element: <BatchOrdersMapping />,
      },
    ],
  },
  {
    name: RouteName.SLARules,
    path: AppRoute.SLARules,
    element: <ServiceLevelAgreements />,
    roles: [Role.FleetAdmin],
    fmsSettings: true,
  },
  {
    name: RouteName.Search,
    path: AppRoute.Search,
    element: <SearchPage />,
  },
];
