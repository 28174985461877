import { UserOption } from "@types";
import { create } from "zustand";

interface UserStore {
  currentUser: UserOption | null;
  setCurrentUser: (user: UserOption | null) => void;
}

export const useUserStore = create<UserStore>((set) => ({
  currentUser: null,
  setCurrentUser: (currentUser) => set(() => ({ currentUser })),
}));
