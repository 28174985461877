import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

type Props = {
  title?: string;
  component?: JSX.Element;
  handleClose: (() => void) | null;
  props?: object;
  styles?: object;
};

const ModalTitle: React.FC<Props> = ({
  title,
  component,
  handleClose,
  props = { variant: "h5" },
  styles = {},
}) => {
  return (
    <DialogTitle
      component={Box}
      display="flex"
      sx={{
        justifyContent: "space-between",
        alignItems: "flex-start",
        ...styles,
      }}>
      {title ? (
        <Typography {...props} lineHeight={1.5}>
          {title}
        </Typography>
      ) : (
        component
      )}
      {handleClose ? (
        <IconButton aria-label="close" onClick={handleClose} sx={{ ml: 4 }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ModalTitle;
