import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { globalConfig } from "@configuration";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Auth: FC = () => {
  const navigate = useNavigate();
  const config = globalConfig.get();

  const { instance } = useMsal();

  const loginRequest = {
    scopes: [`api://${config.clientId}/sign-in`],
  };

  const handleLogin = (loginType: InteractionType) => {
    if (loginType === InteractionType.Silent) {
      instance.loginRedirect(loginRequest).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    }
  };

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      if (localStorage.getItem("lastLocation")) {
        window.location.href = localStorage.getItem("lastLocation") || "/";
        localStorage.removeItem("lastLocation");
      } else {
        navigate("/");
      }
    }
  }, [navigate, isAuthenticated]);

  return (
    <Dialog open={!isAuthenticated} hideBackdrop>
      <Paper
        sx={{
          width: "25.5rem",
          px: 4,
          py: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <img src={"/logo.svg"} alt="logo" width="55px" height="auto" />
        <p
          style={{
            fontSize: "1.5rem",
            marginTop: "1rem",
            marginBottom: "1.5rem",
          }}>
          Welcome to FMS
        </p>

        <Button
          fullWidth
          variant="contained"
          onClick={() => handleLogin(InteractionType.Silent)}>
          Log in
        </Button>
      </Paper>
    </Dialog>
  );
};

export default Auth;
