import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions";

type Props = {
  handleClose: (() => void) | null;
  handleConfirm: () => void;
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  isLoadingConfirm?: boolean;
  isLoadingCancel?: boolean;
  hideCancelButton?: boolean;
  additionalAction?: React.ReactNode;
};

const ModalActions: React.FC<Props> = ({
  handleClose,
  handleConfirm,
  disabledConfirm = false,
  disabledCancel = false,
  confirmBtnText = "Confirm",
  cancelBtnText = "Cancel",
  isLoadingConfirm = false,
  isLoadingCancel = false,
  hideCancelButton = false,
  additionalAction = null,
}) => {
  return (
    <DialogActions sx={{ px: 3, pb: 2, justifyContent: "space-between" }}>
      <div>{additionalAction}</div>
      <div>
        {!hideCancelButton && handleClose ? (
          <LoadingButton
            onClick={handleClose}
            loading={isLoadingCancel}
            disabled={disabledCancel || !handleClose}>
            {cancelBtnText}
          </LoadingButton>
        ) : null}
        <LoadingButton
          variant="contained"
          onClick={handleConfirm}
          disabled={disabledConfirm}
          loading={isLoadingConfirm}>
          {confirmBtnText}
        </LoadingButton>
      </div>
    </DialogActions>
  );
};

export default ModalActions;
