import { AppRoute } from "@enums/FE/AppRoute";
import { RouteName } from "@enums/FE/RouteName";

export const checkIsListItemSelected = ({
  name,
  path,
  sidebarOpen,
  openFMSSettings,
  openFleetSettings,
  openBilling,
}: {
  name: string;
  path: string;
  sidebarOpen: boolean;
  openFMSSettings: boolean;
  openFleetSettings: boolean;
  openBilling: boolean;
}) => {
  if (
    (!sidebarOpen || (sidebarOpen && !openFMSSettings)) &&
    name === RouteName.FMSSettings
  ) {
    return (
      location.pathname.includes(AppRoute.UserManagement) ||
      location.pathname.includes(AppRoute.Users) ||
      location.pathname.includes(AppRoute.SupportingDocs) ||
      location.pathname.includes(AppRoute.TestingTools) ||
      location.pathname.includes(AppRoute.MappingManagement) ||
      location.pathname.includes(AppRoute.SLARules)
    );
  }
  if (
    (!sidebarOpen || (sidebarOpen && !openFleetSettings)) &&
    name === RouteName.FleetSettings
  ) {
    return (
      location.pathname.includes(AppRoute.ServiceCatalog) ||
      location.pathname.includes(AppRoute.RatePlanManagement) ||
      location.pathname.includes(AppRoute.ClientManagement) ||
      location.pathname.includes(AppRoute.DSPs) ||
      location.pathname.includes(AppRoute.HubLocations)
    );
  }
  if (
    (!sidebarOpen || (sidebarOpen && !openBilling)) &&
    name === RouteName.Billing
  ) {
    return (
      location.pathname.includes(AppRoute.BillingBatches) ||
      location.pathname.includes(AppRoute.PaymentBatches)
    );
  }
  return !!(path && location.pathname.startsWith(`/${path}`));
};
