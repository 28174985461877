import { useLayoutEffect, useState } from "react";

export const useContentLoaded = () => {
  const [contentLoaded, setContentLoaded] = useState(false);

  useLayoutEffect(() => {
    setContentLoaded(true);
  }, []);

  return contentLoaded;
};
