import { AppRoute } from "@enums/FE/AppRoute";
import { useHandleBlockedNavigation } from "@hooks/customHooks/useHandleBlockedNavigation";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Logo = () => {
  const location = useLocation();

  const [logoClicked, setClickedStatus] = useState(false);

  const { handleNavigation } = useHandleBlockedNavigation();

  useEffect(() => {
    if (logoClicked) {
      handleNavigation();
    }

    setClickedStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Link
      to={`/${AppRoute.Dashboard}`}
      style={{ textDecoration: "none", color: "inherit" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          columnGap: "0.5rem",
        }}
        onClick={() => setClickedStatus(true)}>
        <img src={"/logo.svg"} alt="logo" />
        <p style={{ fontSize: "1.25rem", fontWeight: 500 }}>FMS</p>
      </div>
    </Link>
  );
};

export default Logo;
