import amber from "@mui/material/colors/amber";
import blue from "@mui/material/colors/blue";
import blueGrey from "@mui/material/colors/blueGrey";
import brown from "@mui/material/colors/brown";
import deepOrange from "@mui/material/colors/deepOrange";
import green from "@mui/material/colors/green";
import indigo from "@mui/material/colors/indigo";
import lightGreen from "@mui/material/colors/lightGreen";
import lime from "@mui/material/colors/lime";
import red from "@mui/material/colors/red";
import teal from "@mui/material/colors/teal";

export enum OrderStatus {
  Ordered = 0,
  Confirmed = 1,
  PickupStorage = 2,
  InTransit = 3,
  Sailed = 11,
  SecondSailed = 12,
  ThirdSailed = 13,
  TransitStorage = 4,
  ExtendedStorage = 14,
  Repairs = 17,
  Plate = 18,
  Blocker = 19,
  Hold = 9,
  ClaimInTransit = 15,
  FinalAssignment = 16,
  DeliveryStorage = 5,
  OutstandingHaulsStorage = 30,
  OutstandingServices = 27,
  Delivered = 6,
  Delivered_MissingDocs = 26,
  Auditing = 28,
  AuditFailed = 7,
  AuditPass = 20,
  AuditRevision = 25,
  QBExported = 21,
  QBFailed = 23,
  QBInvProcessing = 22,
  AutomatedBilling = 29,
  ClientInvoiceGenerated = 24,
  Completed = 8,
  CancelledInvoiceable = 31,
  CancelledNonInvoiceable = 10,
}

export const orderStatusOptions: {
  value: OrderStatus;
  label: string;
  color?: string;
}[] = [
  {
    value: OrderStatus.Ordered,
    label: "Ordered",
    color: blue[200],
  },
  {
    value: OrderStatus.Confirmed,
    label: "Confirmed",
    color: teal[300],
  },
  {
    value: OrderStatus.PickupStorage,
    label: "Pickup Storage",
    color: lime[600],
  },
  {
    value: OrderStatus.InTransit,
    label: "In Transit",
    color: blue[500],
  },
  { value: OrderStatus.Sailed, label: "Sailed", color: blue[700] },
  {
    value: OrderStatus.SecondSailed,
    label: "2nd Sailed",
    color: blue[800],
  },
  {
    value: OrderStatus.ThirdSailed,
    label: "3rd Sailed",
    color: blue[900],
  },
  {
    value: OrderStatus.TransitStorage,
    label: "Transit Storage",
    color: green[300],
  },
  {
    value: OrderStatus.ExtendedStorage,
    label: "Extended Storage",
    color: lime[800],
  },
  {
    value: OrderStatus.Repairs,
    label: "Repairs",
    color: brown[300],
  },
  {
    value: OrderStatus.Plate,
    label: "Plate",
    color: brown[500],
  },
  {
    value: OrderStatus.Blocker,
    label: "BLOCKER",
    color: brown[900],
  },
  { value: OrderStatus.Hold, label: "Hold", color: amber[700] },
  {
    value: OrderStatus.ClaimInTransit,
    label: "Claim in Transit",
    color: red[400],
  },
  {
    value: OrderStatus.FinalAssignment,
    label: "Final Assignment",
    color: green[600],
  },
  {
    value: OrderStatus.DeliveryStorage,
    label: "Delivery Storage",
    color: green[400],
  },
  {
    value: OrderStatus.OutstandingHaulsStorage,
    label: "Outstanding Hauls/Storage",
    color: blueGrey[600],
  },
  {
    value: OrderStatus.OutstandingServices,
    label: "Outstanding Services",
    color: blueGrey[300],
  },
  {
    value: OrderStatus.Delivered,
    label: "Delivered",
    color: teal[800],
  },
  {
    value: OrderStatus.Delivered_MissingDocs,
    label: "Delivered - Missing Docs",
    color: amber[900],
  },
  {
    value: OrderStatus.Auditing,
    label: "Auditing",
    color: indigo[400],
  },
  {
    value: OrderStatus.AuditFailed,
    label: "Audit Failed",
    color: deepOrange[600],
  },
  {
    value: OrderStatus.AuditPass,
    label: "Audit Pass",
    color: lightGreen[700],
  },
  {
    value: OrderStatus.AuditRevision,
    label: "Audit Revision",
    color: indigo[500],
  },
  {
    value: OrderStatus.QBExported,
    label: "QB Exported",
    color: teal[600],
  },
  {
    value: OrderStatus.QBFailed,
    label: "QB Failed",
    color: deepOrange["A400"],
  },
  {
    value: OrderStatus.QBInvProcessing,
    label: "QB Inv. Processing",
    color: teal["A700"],
  },
  {
    value: OrderStatus.AutomatedBilling,
    label: "Automated Billing",
    color: blueGrey[500],
  },
  {
    value: OrderStatus.ClientInvoiceGenerated,
    label: "Client Invoice Generated",
    color: green["A700"],
  },
  {
    value: OrderStatus.Completed,
    label: "Completed",
    color: green[800],
  },
  {
    value: OrderStatus.CancelledInvoiceable,
    label: "Cancelled - Invoiceable",
    color: red[600],
  },
  {
    value: OrderStatus.CancelledNonInvoiceable,
    label: "Cancelled - Non-Invoiceable",
    color: red[800],
  },
];

export const OrderStatusMapping: Record<number, string> = {
  [OrderStatus.Ordered]: "Ordered",
  [OrderStatus.Confirmed]: "Confirmed",
  [OrderStatus.PickupStorage]: "Pickup Storage",
  [OrderStatus.InTransit]: "In Transit",
  [OrderStatus.Sailed]: "Sailed",
  [OrderStatus.SecondSailed]: "2nd Sailed",
  [OrderStatus.ThirdSailed]: "3rd Sailed",
  [OrderStatus.TransitStorage]: "Transit Storage",
  [OrderStatus.ExtendedStorage]: "Extended Storage",
  [OrderStatus.Repairs]: "Repairs",
  [OrderStatus.Plate]: "Plate",
  [OrderStatus.Blocker]: "BLOCKER",
  [OrderStatus.Hold]: "Hold",
  [OrderStatus.ClaimInTransit]: "Claim in Transit",
  [OrderStatus.FinalAssignment]: "Final Assignment",
  [OrderStatus.DeliveryStorage]: "Delivery Storage",
  [OrderStatus.Delivered]: "Delivered",
  [OrderStatus.AuditFailed]: "Audit Failed",
  [OrderStatus.Delivered_MissingDocs]: "Delivered - Missing Docs",
  [OrderStatus.Completed]: "Completed",
  [OrderStatus.CancelledInvoiceable]: "Cancelled - Invoiceable",
  [OrderStatus.CancelledNonInvoiceable]: "Cancelled - Non-Invoiceable",
  [OrderStatus.AuditPass]: "Audit Pass",
  [OrderStatus.AuditRevision]: "Audit Revision",
  [OrderStatus.QBInvProcessing]: "QB Inv. Processing",
  [OrderStatus.QBExported]: "QB Exported",
  [OrderStatus.QBFailed]: "QB Failed",
  [OrderStatus.ClientInvoiceGenerated]: "Client Invoice Generated",
  [OrderStatus.OutstandingServices]: "Outstanding Services",
  [OrderStatus.OutstandingHaulsStorage]: "Outstanding Hauls/Storage",
  [OrderStatus.Auditing]: "Auditing",
  [OrderStatus.AutomatedBilling]: "Automated Billing",
};
