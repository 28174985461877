import { AppRoute } from "@enums/FE/AppRoute";
import { useDebounce } from "@hooks/customHooks/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { useNavigationBlockerStore } from "@store/useNavigationBlockerStore";
import { useSearchQuery } from "@store/useSearchQuery";
import { memo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Search = styled("div")<{ navigationBlocked: boolean }>(
  ({ theme, navigationBlocked }) => ({
    position: "relative",
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: navigationBlocked
        ? alpha(theme.palette.common.white, 0.15)
        : alpha(theme.palette.common.white, 0.25),
    },
    display: "flex",
    alignItems: "center",
  })
);

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.875),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    width: "15rem",
  },
}));

const SearchBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { query, setQuery, prevLocation, setPrevLocation } = useSearchQuery();
  const { navigationBlocked } = useNavigationBlockerStore();

  const debouncedQuery = useDebounce(query);

  useEffect(() => {
    if (debouncedQuery.trim() && location.pathname !== `/${AppRoute.Search}`) {
      navigate(`/${AppRoute.Search}`);
    } else if (
      !debouncedQuery.trim() &&
      location.pathname === `/${AppRoute.Search}`
    ) {
      if (prevLocation) {
        navigate(-1);
      } else {
        navigate(`/${AppRoute.Orders}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    if (
      prevLocation !== location.pathname &&
      location.pathname !== `/${AppRoute.Search}`
    ) {
      setQuery("");
    }

    return () => {
      if (location.pathname !== prevLocation) {
        setPrevLocation(location.pathname);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, prevLocation]);

  return (
    <Search navigationBlocked={navigationBlocked}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="VIN or Phone or Order # or SO #"
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        sx={{ pointerEvents: navigationBlocked ? "none" : "inherit" }}
      />
    </Search>
  );
};

export default memo(SearchBox);
