export enum QueryKey {
  DashboardTabs = "dashboardTabs",
  Users = "users",
  Orders = "orders",
  Order = "order",
  BatchOrders = "batchOrders",
  Services = "services",
  Clients = "clients",
  TableConfig = "tableConfig",
  Reminders = "reminders",
  ClientRatePlans = "clientRatePlans",
  Reports = "reports",
  Logs = "logs",
  Attachments = "attachments",
  Carriers = "carriers",
  Revenue = "revenue",
  OrdersFilters = "ordersFilters",
  RemindersFilters = "remindersFilters",
  Hauls = "hauls",
  Haul = "haul",
  OrderStatuses = "orderStatuses",
  Address = "address",
  Notes = "notes",
  StorageRates = "storageRates",
  Billing = "billing",
  ChargeCategories = "chargeCategories",
  BillingBatches = "billingBatches",
  BillingBatch = "billingBatch",
  BillingBatchesFilters = "billingBatchesFilters",
  PaymentBatches = "paymentBatches",
  PaymentBatch = "paymentBatch",
  PaymentBatchesFilters = "paymentBatchesFilters",
  CarrierInvoice = "carrierInvoice",
  PaymentCalculator = "paymentCalculator",
  ClientSettings = "clientSettings",
  DocCategories = "docCategories",
  RequiredDocs = "requiredDocs",
  Placeholders = "placeholders",
  DocLinkItems = "docLinkItems",
  OrderAssociatedItems = "orderAssociatedItems",
  BatchMappings = "batchMappings",
  BatchMappingsFilters = "batchMappingsFilters",
  Storage = "storage",
  BillingMappings = "billingMappings",
  BillingMappingsFilters = "billingMappingsFilters",
  RebateSettings = "rebateSettings",
  DSPs = "DSPs",
  HubLocations = "hubLocations",
  SLAs = "SLAs",
  SLARationales = "SLARationales",
  ServiceTags = "serviceTags",
}
