import { create } from "zustand";

interface CreateOrderStote {
  openCreateOrderModal: boolean;
  setOpenCreateOrderModal: (open: boolean) => void;
}

export const useCreateOrderStore = create<CreateOrderStote>((set) => ({
  openCreateOrderModal: false,
  setOpenCreateOrderModal: (open: boolean) =>
    set(() => ({ openCreateOrderModal: open })),
}));
