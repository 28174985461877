import AppDrawer from "@components/styled/AppDrawer";
import DrawerHeader from "@components/styled/DrawerHeader";
import { globalConfig } from "@configuration";
import { SIDEBAR_WIDTH } from "@constants";
import { Role } from "@enums/FE/Role";
import { RouteName } from "@enums/FE/RouteName";
import { filterRoutes } from "@helpers/filterRoutes";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import { useSideMenuStore } from "@store/useSideMenuStore";
import { useUserStore } from "@store/useUserStore";
import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import ExpandableSidebarItem from "./ExpandableSidebarItem";
import SidebarListButton from "./SidebarListButton";

const Sidebar = () => {
  const { currentUser } = useUserStore();
  const {
    sideMenuOpen,
    sideMenuHovered,
    setSideMenuHovered,
    openFMSSettings,
    openFleetSettings,
    openBilling,
  } = useSideMenuStore();

  const config = globalConfig.get();

  const appRoutes = filterRoutes({ config });

  const menuRoutes = useMemo(
    () =>
      (appRoutes || []).filter(
        (route) =>
          (!!route.icon ||
            route.fmsSettings ||
            route.fleetSettings ||
            (config?.enableBatches ? route.billing : false)) &&
          route.roles?.includes((currentUser?.roleName || "") as Role)
      ),
    [currentUser?.roleName, appRoutes, config?.enableBatches]
  );

  const handleMouseEnter = () => setSideMenuHovered(true);
  const handleMouseLeave = () => setSideMenuHovered(false);

  const SIDEBAR_OPEN = sideMenuOpen || sideMenuHovered;

  return (
    <AppDrawer
      variant="permanent"
      open={SIDEBAR_OPEN}
      width={SIDEBAR_WIDTH}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      PaperProps={{
        sx: ({ palette }) => ({
          color: palette.info.contrastText,
          backgroundColor: palette.info.main,
        }),
      }}>
      <DrawerHeader sx={{ mb: 0.5 }} />

      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <List disablePadding>
          {menuRoutes
            .filter(
              (route) =>
                !route?.fmsSettings && !route.fleetSettings && !route.billing
            )
            .map(({ name, icon, path }) => (
              <Fragment key={name}>
                <ListItem disablePadding>
                  {path ? (
                    <Link
                      to={`/${path}`}
                      style={{ display: "block", width: "100%" }}>
                      <SidebarListButton name={name} icon={icon} path={path} />
                    </Link>
                  ) : (
                    <SidebarListButton name={name} icon={icon} path={path} />
                  )}
                </ListItem>

                {SIDEBAR_OPEN && name === RouteName.FMSSettings ? (
                  <ExpandableSidebarItem
                    openSettings={openFMSSettings}
                    routes={menuRoutes.filter((route) => !!route?.fmsSettings)}
                  />
                ) : null}

                {SIDEBAR_OPEN && name === RouteName.FleetSettings ? (
                  <ExpandableSidebarItem
                    openSettings={openFleetSettings}
                    routes={menuRoutes.filter(
                      (route) => !!route?.fleetSettings
                    )}
                  />
                ) : null}

                {SIDEBAR_OPEN && name === RouteName.Billing ? (
                  <ExpandableSidebarItem
                    openSettings={openBilling}
                    routes={menuRoutes.filter((route) => !!route?.billing)}
                  />
                ) : null}
              </Fragment>
            ))}
        </List>

        <div style={{ padding: "0 1rem 0.5rem" }}>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "1rem",
                }}>{`v${config.version_full}`}</span>
            }>
            <span style={{ fontSize: "0.75rem", cursor: "default" }}>
              v{config.version_short}
            </span>
          </Tooltip>
        </div>
      </div>
    </AppDrawer>
  );
};

export default Sidebar;
