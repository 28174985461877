import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { globalConfig, globalConfigUrl } from "@configuration";
import { handleLogout } from "@helpers/handleLogout";
import axios, { AxiosHeaders } from "axios";
import ReactDOM from "react-dom/client";
import App from "./App";

axios.defaults.baseURL = "/";

axios
  .get(globalConfigUrl)
  .then((response) => {
    globalConfig.set(response.data);
    axios.defaults.baseURL = response.data.apiUrl;

    const msalConfig = {
      auth: {
        clientId: response.data.clientId,
        authority:
          "https://login.microsoftonline.com/93ce0c0f-5c89-4ed2-a0ed-73838cc9c90a",
      },
      cache: { cacheLocation: "localStorage" },
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    const refreshAccessToken = async () => {
      const currentAccount = msalInstance.getAllAccounts();
      if (!currentAccount) {
        handleLogout();
      }

      const loginRequest = {
        scopes: [`api://${response.data.clientId}/sign-in`],
        account: currentAccount?.[0],
        redirectUri:
          localStorage.getItem("lastLocation") || window.location.origin,
      };

      try {
        const token = await msalInstance.acquireTokenSilent(loginRequest);
        return token.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          return msalInstance
            .acquireTokenPopup(loginRequest)
            .then((resp) => {
              return resp.accessToken;
            })
            .catch(() => {
              handleLogout();
            });
        } else {
          handleLogout();
        }
      }
    };

    axios.interceptors.request.use(
      async (config) => {
        const token = await refreshAccessToken();
        if (token) {
          if (!config.headers) {
            config.headers = {} as AxiosHeaders;
          }
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async (error) => {
        if (error?.response?.status === 401) {
          const token = await refreshAccessToken();
          if (token) {
            axios.interceptors.request.use(
              async (config) => {
                if (!config.headers) {
                  config.headers = {} as AxiosHeaders;
                }
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
              },
              (error) => Promise.reject(error)
            );
          }
          return error.config;
        } else {
          return Promise.reject(error);
        }
      }
    );

    return (
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    );
  })
  .catch(() => {
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        Error while fetching global config
      </p>
    );
  })
  .then((reactElement: React.ReactElement) => {
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );
    root.render(reactElement);
  });
