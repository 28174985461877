import { create } from "zustand";

import { GetOrderNoteDto } from "@services/fms-api";

interface OrderNotesStore {
  openNewNoteModal: boolean;
  setOpenNewNoteModal: (open: boolean) => void;

  noteToEdit: GetOrderNoteDto | null;
  setNoteToEdit: (note: GetOrderNoteDto | null) => void;

  isBlockerNote: boolean;
  setIsBlockerNote: (isBlocker: boolean) => void;
}

export const useOrderNotesStore = create<OrderNotesStore>((set) => ({
  openNewNoteModal: false,
  setOpenNewNoteModal: (open: boolean) =>
    set(() => ({ openNewNoteModal: open })),

  noteToEdit: null,
  setNoteToEdit: (note: GetOrderNoteDto | null) =>
    set(() => ({ noteToEdit: note })),

  isBlockerNote: false,
  setIsBlockerNote: (isBlocker) => set(() => ({ isBlockerNote: isBlocker })),
}));
