import { globalConfig } from "@configuration";
import { filterRoutes } from "@helpers/filterRoutes";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSideMenuStore } from "@store/useSideMenuStore";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../routes";

type Props = {
  openSettings: boolean;
  routes: typeof routes;
};

const ExpandableSidebarItem: React.FC<Props> = ({ openSettings, routes }) => {
  const location = useLocation();
  const config = globalConfig.get();

  const { sideMenuOpen, sideMenuHovered } = useSideMenuStore();

  const SIDEBAR_OPEN = sideMenuOpen || sideMenuHovered;

  const appRoutes = filterRoutes({ selectedRoutes: routes, config });

  return (
    <Collapse in={openSettings} timeout="auto" unmountOnExit>
      <List>
        {appRoutes.map(({ name, icon, path }) => (
          <ListItem key={name} disablePadding>
            <Link to={`/${path}`} style={{ display: "block", width: "100%" }}>
              <ListItemButton
                sx={({ palette }) => ({
                  "&.MuiListItemButton-root.Mui-selected": {
                    backgroundColor: palette.info.contrastText,
                    "& svg": { fill: palette.info.main },
                    "& .MuiListItemText-root": { color: palette.info.main },
                  },
                })}
                selected={path && location.pathname.includes(path)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: SIDEBAR_OPEN ? 3 : "auto",
                    justifyContent: "center",
                  }}>
                  {icon}
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={{ opacity: SIDEBAR_OPEN ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default ExpandableSidebarItem;
