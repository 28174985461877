import { DynamicConfig } from "@configuration";
import { AppRoute } from "@enums/FE/AppRoute";
import { RouteName } from "@enums/FE/RouteName";
import { routes } from "../../routes";

export const filterRoutes = ({
  config,
  selectedRoutes,
}: {
  config: DynamicConfig;
  selectedRoutes?: typeof routes;
}) =>
  (selectedRoutes ?? routes).reduce((acc: typeof routes, route) => {
    if (route.path === AppRoute.Dashboard) {
      if (config.enableDashboard) {
        acc.push(route);
      }
    } else if (route.path === AppRoute.TestingTools) {
      if (config.enableEmulateMonthlyBilling) {
        acc.push(route);
      }
    } else if (
      route.path === AppRoute.BillingBatches ||
      route.path === AppRoute.BillingBatchDetails ||
      route.path === AppRoute.PaymentBatches ||
      route.path === AppRoute.PaymentBatchDetails ||
      route.name === RouteName.Billing
    ) {
      if (config.enableBatches) {
        acc.push(route);
      }
    } else {
      acc.push(route);
    }
    return acc;
  }, []);
