import { create } from "zustand";

interface OnBeforeLeaveStore {
  onBeforeLeaveCb?: (() => void) | null;
  setOnBeforeLeaveCb: (cb?: (() => void) | null) => void;
}

export const useOnBeforeLeaveStore = create<OnBeforeLeaveStore>((set) => ({
  onBeforeLeaveCb: null,
  setOnBeforeLeaveCb: (cb) => set(() => ({ onBeforeLeaveCb: cb })),
}));
