export interface DynamicConfig {
  apiUrl: string;
  rpsUrl: string;
  clientId: string;
  version_short: string;
  version_full: string;
  appInsightsConnectionString: string;
  rpsLink: string;
  enableDashboard: boolean;
  enableBatches: boolean;
  enableEmulateMonthlyBilling: boolean;
  enableCloneOrder: boolean;
}

export const defaultConfig: DynamicConfig = {
  apiUrl: "https://fms-api-dev.azurewebsites.net/api/",
  rpsUrl: "https://ra-rps-api-dev.azurewebsites.net/api/",
  clientId: "615aa558-21ff-4e49-875b-53a483a597c6",
  version_short: "0.0.0",
  version_full: "0.0.0-0",
  appInsightsConnectionString: "",
  rpsLink: "http://rpstraining.reindeerauto.com/",
  enableDashboard: true,
  enableBatches: true,
  enableEmulateMonthlyBilling: true,
  enableCloneOrder: true,
};

class GlobalConfig {
  config: DynamicConfig = defaultConfig;
  notDefinedYet = true;

  public get(): DynamicConfig {
    if (this.notDefinedYet) {
      throw new Error("Global config has not been defined yet.");
    } else {
      return this.config;
    }
  }

  public set(value: DynamicConfig): void {
    if (this.notDefinedYet) {
      this.config = value;
      const shortString = this.config.version_full.split("-");
      this.config.version_short = shortString?.[0] ?? this.config.version_full;

      this.notDefinedYet = false;
    } else {
      throw new Error("Global config has already been defined");
    }
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "globalConfig.json";
