import { AppRoute } from "@enums/FE/AppRoute";
import { SessionStorageFormData } from "@enums/FE/SessionStorageFormData";
import { useCreateOrderStore } from "@store/useCreateOrderStore";
import { useOrderNotesStore } from "@store/useOrderNotesStore";
import { useReminderStore } from "@store/useReminderStore";
import { useSearchQuery } from "@store/useSearchQuery";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useHandleBlockedNavigation = () => {
  const location = useLocation();
  const { setQuery, setPrevLocation } = useSearchQuery();
  const { setOpenNewNoteModal, setNoteToEdit } = useOrderNotesStore();
  const { setOpenCreateOrderModal } = useCreateOrderStore();
  const { setOpenReminderModal } = useReminderStore();

  const handleNavigation = useCallback(() => {
    setOpenCreateOrderModal(false);
    setOpenNewNoteModal(false);
    setNoteToEdit(null);
    setOpenReminderModal(false);
    setQuery("");
    setPrevLocation("");
    if (location.pathname.includes(AppRoute.CreateOrderSingle)) {
      sessionStorage.removeItem(SessionStorageFormData.NewSingleOrder);
    }
    if (location.pathname.includes(AppRoute.MappingManagement)) {
      sessionStorage.removeItem(SessionStorageFormData.NewBillingExport);
      sessionStorage.removeItem(SessionStorageFormData.BillingExportFile);
    }
  }, [
    location.pathname,
    setQuery,
    setPrevLocation,
    setNoteToEdit,
    setOpenCreateOrderModal,
    setOpenNewNoteModal,
    setOpenReminderModal,
  ]);

  return { handleNavigation };
};
