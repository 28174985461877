export enum RouteName {
  Dashboard = "Dashboard",
  Orders = "Orders",
  Reports = "Reports",
  Reminders = "Reminders",
  Carriers = "Carriers",
  FleetSettings = "Fleet Settings",
  ServiceCatalog = "Service Catalog",
  RatePlanManagement = "Rate Plan Mgmt.",
  ClientManagement = "Client Mgmt.",
  FMSSettings = "FMS Settings",
  Users = "Users",
  UserManagement = "User Mgmt.",
  Search = "Search Results",
  TestingTools = "Testing Tools",
  Billing = "Billing",
  BillingBatches = "Receivables",
  PaymentBatches = "Payables",
  SupportingDocs = "Supporting Docs",
  MappingManagement = "Mapping Mgmt.",
  DSPs = "DSPs",
  HubLocations = "Hub Locations",
  SLARules = "SLA Settings",
}
