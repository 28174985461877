import { Role } from "@enums/FE/Role";
import { useUserStore } from "@store/useUserStore";

export const useUserRole = () => {
  const { currentUser } = useUserStore();

  const isFleetAdmin = currentUser?.roleName === Role.FleetAdmin;
  const isBillingRole = currentUser?.roleName === Role.Billing;
  const isCSMRole = currentUser?.roleName === Role.CSM;
  const isAdminOrBilling = isFleetAdmin || isBillingRole;
  const isAdminOrCSM = isFleetAdmin || isCSMRole;

  return {
    isFleetAdmin,
    isAdminOrBilling,
    isAdminOrCSM,
  };
};
