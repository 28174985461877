import MUIDrawer, { DrawerProps } from "@mui/material/Drawer";
import { CSSObject, Theme, styled } from "@mui/material/styles";

interface AppDrawerProps extends DrawerProps {
  open: boolean;
  width: number;
}

const openedMixin = (theme: Theme, width: number): CSSObject => ({
  width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const AppDrawer = styled(MUIDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "width",
})<AppDrawerProps>(({ theme, open, width }) => ({
  width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiListItemIcon-root svg": {
    color: theme.palette.info.contrastText,
  },
  "& .MuiListItem-root a": {
    color: theme.palette.info.contrastText,
    textDecoration: "none",
  },
  ...(open && {
    ...openedMixin(theme, width),
    "& .MuiDrawer-paper": openedMixin(theme, width),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default AppDrawer;
