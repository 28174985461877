import { AppRoute } from "@enums/FE/AppRoute";
import LogoutTwoTone from "@mui/icons-material/LogoutTwoTone";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { useSearchQuery } from "@store/useSearchQuery";
import { useUserStore } from "@store/useUserStore";
import { useNavigate } from "react-router-dom";

const User = () => {
  const navigate = useNavigate();
  const { setQuery } = useSearchQuery();
  const { currentUser } = useUserStore();

  if (!currentUser?.roleName) {
    return <div style={{ marginRight: "1rem" }} />;
  }

  const onLogout = () => {
    setQuery("");
    localStorage.clear();
    navigate(`/${AppRoute.Auth}`);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: "0.25rem",
          padding: "0.25rem 0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginRight: "0.5rem",
        }}>
        <Chip
          size="small"
          label={currentUser.roleName}
          sx={{
            color: "#fff",
            backgroundColor: "rgba(255,255,255,0.15)",
            fontWeight: 500,
          }}
        />

        <p
          style={{
            color: "inherit",
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: "0.875rem",
            whiteSpace: "nowrap",
          }}>
          {currentUser?.label || ""}
        </p>
      </div>

      <Button color="inherit" startIcon={<LogoutTwoTone />} onClick={onLogout}>
        Log out
      </Button>
    </>
  );
};

export default User;
