import { create } from "zustand";

interface NavigationBlockerStoreValues {
  navigationBlocked: boolean;
  onLeaveCallback: ((...args: unknown[]) => void) | null;
}

interface DashboardStore extends NavigationBlockerStoreValues {
  setNavigationStoreValues: (
    values: Partial<NavigationBlockerStoreValues>
  ) => void;
  resetNavigationStoreValues: () => void;
}

const defaultStoreValues: NavigationBlockerStoreValues = {
  navigationBlocked: false,
  onLeaveCallback: null,
};

const useNavigationBlockerStore = create<DashboardStore>((set) => ({
  ...defaultStoreValues,
  setNavigationStoreValues: (values) => set({ ...values }),
  resetNavigationStoreValues: () => set(defaultStoreValues),
}));

export { useNavigationBlockerStore };
