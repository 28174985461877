import { useMsal } from "@azure/msal-react";
import FullScreenLoader from "@components/FullScreenLoader";
import NavigationBlockerModal from "@components/modals/NavigationBlockerModal";
import DrawerHeader from "@components/styled/DrawerHeader";
import { SIDEBAR_WIDTH } from "@constants";
import { AppRoute } from "@enums/FE/AppRoute";
import { useNavigationBlockerModalProps } from "@hooks/customHooks/useNavigationBlockerModalProps";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { useFetchUsers } from "@hooks/users/useFetchUsers";
import CreateNewOrderModal from "@modules/NewOrder/CreateNewOrderModal";
import { useCreateOrderStore } from "@store/useCreateOrderStore";
import { useReminderStore } from "@store/useReminderStore";
import { useUserStore } from "@store/useUserStore";
import { useEffect } from "react";
import { lazy, Suspense } from "react-lazy-no-flicker";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const UpdateReminderFlow = lazy(
  () => import("@modules/Reminders/UpdateReminderFlow"),
  { time_before_fallback: 1000 }
);

const Layout = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { userOptions, isFetchingUsers } = useFetchUsers();
  const { currentUser, setCurrentUser } = useUserStore();
  const { showSnackbarMessage } = useSnackbarMessage();
  const { openCreateOrderModal } = useCreateOrderStore();
  const { openReminderModal } = useReminderStore();

  useEffect(() => {
    if (instance && userOptions) {
      const setUsersAndRoles = async () => {
        const currentAccount = instance.getAllAccounts()?.[0];
        const currUser =
          userOptions.find(
            (option) => option.email === currentAccount.username
          ) || null;
        if (!currentAccount || !currUser) {
          showSnackbarMessage("User not found", "error");
          localStorage.clear();
          navigate(`/${AppRoute.Auth}`);
        }
        setCurrentUser(currUser);
      };

      setUsersAndRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, userOptions, setCurrentUser]);

  const navigationBlockerProps = useNavigationBlockerModalProps();

  useEffect(() => {
    if (currentUser && !currentUser.roleName) {
      showSnackbarMessage("User has no role assigned", "error");
      localStorage.clear();
      navigate(`/${AppRoute.Auth}`);
    }
  }, [currentUser, navigate, showSnackbarMessage]);

  return (
    <>
      <div style={{ display: "flex", minHeight: "100vh", overflow: "hidden" }}>
        <Header />

        <Sidebar />

        {currentUser ? (
          <main
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
              maxHeight: "100vh",
              backgroundColor: "#F5F5F5",
              overflowX: "auto",
            }}>
            <DrawerHeader />
            <div style={{ margin: "1.5rem 1rem", flex: 1, minWidth: "1200px" }}>
              {children}
            </div>
          </main>
        ) : null}
      </div>

      <NavigationBlockerModal {...navigationBlockerProps} />

      {openReminderModal && (
        <Suspense fallback={<FullScreenLoader />}>
          <UpdateReminderFlow />
        </Suspense>
      )}

      {openCreateOrderModal && <CreateNewOrderModal />}

      {isFetchingUsers ? <FullScreenLoader /> : null}
    </>
  );
};

export default Layout;
