import ModalTitle from "@components/modals/ModalTitle";
import { AppRoute } from "@enums/FE/AppRoute";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import { useCreateOrderStore } from "@store/useCreateOrderStore";
import { useNavigate } from "react-router-dom";

const CreateNewOrderModal = () => {
  const navigate = useNavigate();
  const { setOpenCreateOrderModal } = useCreateOrderStore();

  const handleClose = () => setOpenCreateOrderModal(false);

  const handleClick = (
    route: AppRoute.CreateOrderSingle | AppRoute.CreateOrderBatch
  ) => {
    navigate(`/${route}`);
    handleClose();
  };

  return (
    <Dialog open={true}>
      <ModalTitle title="Create New Order" handleClose={handleClose} />

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "30rem",
        }}>
        <DialogContentText mb={1} fontSize="0.95rem">
          Manually create a new order
        </DialogContentText>
        <Button
          variant="contained"
          sx={{ p: 2, justifyContent: "start" }}
          onClick={() => handleClick(AppRoute.CreateOrderSingle)}>
          Single Order
        </Button>

        <Divider
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            marginY: 2,
            fontSize: "0.95rem",
          }}>
          or
        </Divider>

        <DialogContentText mb={1} fontSize="0.95rem">
          Upload spreadsheet for order entry
        </DialogContentText>
        <Button
          variant="contained"
          sx={{ p: 2, justifyContent: "start" }}
          onClick={() => handleClick(AppRoute.CreateOrderBatch)}>
          Batch Order
        </Button>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewOrderModal;
