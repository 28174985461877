import { ReminderDto } from "@services/fms-api";
import { create } from "zustand";

interface ReminderStore {
  openReminderModal: boolean;
  setOpenReminderModal: (open: boolean) => void;

  reminderToEdit: ReminderDto | null;
  setReminderToEdit: (reminderToEdit: ReminderDto | null) => void;
}

export const useReminderStore = create<ReminderStore>((set) => ({
  openReminderModal: false,
  setOpenReminderModal: (openReminderModal) =>
    set(() => ({ openReminderModal })),

  reminderToEdit: null,
  setReminderToEdit: (reminderToEdit) => set(() => ({ reminderToEdit })),
}));
