import ErrorBoundary from "@components/ErrorBoundary";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import moment from "moment-timezone";
import { SnackbarProvider } from "notistack";
import { globalConfig } from "./configuration";
import Router from "./Router";
import "./styles.css";
import { queryClient } from "./utils/queryClient";
import theme from "./utils/theme";

moment.tz.setDefault("America/New_York");

let reactPlugin: ReactPlugin | null = null;

const App = () => {
  const config = globalConfig.get();

  reactPlugin = new ReactPlugin();

  if (config?.appInsightsConnectionString) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: config.appInsightsConnectionString,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [reactPlugin],
        correlationHeaderExcludedDomains: ["maps.googleapis.com"],
      },
    });

    const telemetryInitializer = (item: any) => {
      if (item.data !== undefined) {
        item.data.component = "FMS Web";
        item.data.Version = config.version_full;
      }
    };

    appInsights.loadAppInsights(); // To initiate session for the user
    appInsights.trackPageView(); // To track every page views
    appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}>
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </LocalizationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default reactPlugin ? withAITracking(reactPlugin, App) : App;
