import { AppRoute } from "@enums/FE/AppRoute";
import { RouteName } from "@enums/FE/RouteName";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSideMenuStore } from "@store/useSideMenuStore";
import { checkIsListItemSelected } from "./helpers/checkIsListItemSelected";

type Props = {
  name?: RouteName;
  icon?: JSX.Element;
  path?: AppRoute;
};

const SidebarListButton: React.FC<Props> = ({ name, icon, path }) => {
  const {
    sideMenuOpen,
    sideMenuHovered,
    openFMSSettings,
    setOpenFMSSettings,
    openFleetSettings,
    setOpenFleetSettings,
    openBilling,
    setOpenBilling,
  } = useSideMenuStore();

  const routeHandlers: Partial<Record<RouteName, () => void>> = {
    [RouteName.FMSSettings]: () => setOpenFMSSettings(!openFMSSettings),
    [RouteName.FleetSettings]: () => setOpenFleetSettings(!openFleetSettings),
    [RouteName.Billing]: () => setOpenBilling(!openBilling),
  };

  const handleClick = () => {
    if (name) {
      const handler = routeHandlers[name];
      if (handler) {
        handler();
      }
    }
  };

  const SIDEBAR_OPEN = sideMenuOpen || sideMenuHovered;

  const isSelected = checkIsListItemSelected({
    name: name || "",
    path: path || "",
    sidebarOpen: SIDEBAR_OPEN,
    openFMSSettings,
    openFleetSettings,
    openBilling,
  });

  return (
    <ListItemButton
      sx={({ palette }) => ({
        "&.MuiListItemButton-root.Mui-selected": {
          backgroundColor: palette.info.contrastText,
          "& svg": { fill: palette.info.main },
          "& .MuiListItemText-root": { color: palette.info.main },
        },
      })}
      selected={isSelected}
      onClick={handleClick}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: SIDEBAR_OPEN ? 3 : "auto",
          justifyContent: "center",
        }}>
        {icon}
      </ListItemIcon>

      <ListItemText primary={name} sx={{ opacity: SIDEBAR_OPEN ? 1 : 0 }} />

      {name === RouteName.FMSSettings && SIDEBAR_OPEN ? (
        openFMSSettings ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : null}
      {name === RouteName.FleetSettings && SIDEBAR_OPEN ? (
        openFleetSettings ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : null}
      {name === RouteName.Billing && SIDEBAR_OPEN ? (
        openBilling ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : null}
    </ListItemButton>
  );
};

export default SidebarListButton;
