import { create } from "zustand";

interface SideMenuStore {
  sideMenuOpen: boolean;
  setSideMenuOpen: (open: boolean) => void;

  sideMenuHovered: boolean;
  setSideMenuHovered: (open: boolean) => void;

  openFMSSettings: boolean;
  setOpenFMSSettings: (open: boolean) => void;

  openFleetSettings: boolean;
  setOpenFleetSettings: (open: boolean) => void;

  openBilling: boolean;
  setOpenBilling: (open: boolean) => void;
}

export const useSideMenuStore = create<SideMenuStore>((set) => ({
  sideMenuOpen: false,
  setSideMenuOpen: (sideMenuOpen) => set(() => ({ sideMenuOpen })),

  sideMenuHovered: false,
  setSideMenuHovered: (sideMenuHovered) => set(() => ({ sideMenuHovered })),

  openFMSSettings: true,
  setOpenFMSSettings: (openFMSSettings) => set(() => ({ openFMSSettings })),

  openFleetSettings: true,
  setOpenFleetSettings: (openFleetSettings) =>
    set(() => ({ openFleetSettings })),

  openBilling: true,
  setOpenBilling: (openBilling) => set(() => ({ openBilling })),
}));
