import { create } from "zustand";

interface SearchQueryState {
  query: string;
  setQuery: (value: string) => void;

  prevLocation: string;
  setPrevLocation: (value: string) => void;
}

export const useSearchQuery = create<SearchQueryState>((set) => ({
  query: "",
  setQuery: (value: string) => set(() => ({ query: value })),

  prevLocation: "",
  setPrevLocation: (value: string) => set(() => ({ prevLocation: value })),
}));
