import FullScreenLoader from "@components/FullScreenLoader";
import { useContentLoaded } from "@hooks/customHooks/useContentLoaded";
import { useOrderPermissions } from "@hooks/customHooks/useOrderPermissions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Breakpoint, SxProps, Theme } from "@mui/material/styles";
import { useNavigationBlockerStore } from "@store/useNavigationBlockerStore";
import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { lazy, Suspense } from "react-lazy-no-flicker";
import ModalActions from "./ModalActions";
import ModalTitle from "./ModalTitle";

const AccountingStatusChangeConfirmation = lazy(
  () => import("./AccountingStatusChangeConfirmation"),
  { time_before_fallback: 1000 }
);

type Props<T extends FieldValues> = {
  handleClose: (() => void) | null;
  handleConfirm?: (data: T, cb?: () => void) => void;
  title: string;
  text?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  isLoadingConfirm?: boolean;
  children?: React.ReactNode;
  maxWidth?: Breakpoint;
  disabledConfirm?: boolean;
  styles?: SxProps<Theme>;
  withoutActions?: boolean;
  shouldBlock?: boolean;
  withAuditWarning?: boolean;
  formMethods?: UseFormReturn<T, any>;
  handleError?: () => void;
  additionalAction?: React.ReactNode;
};

const BasicModal = <T extends FieldValues>({
  handleClose,
  handleConfirm,
  title,
  text = "",
  confirmBtnText = "Confirm",
  cancelBtnText = "Cancel",
  isLoadingConfirm = false,
  children = null,
  maxWidth = "sm",
  disabledConfirm = false,
  styles,
  withoutActions = false,
  shouldBlock = false,
  withAuditWarning = false,
  formMethods,
  handleError,
  additionalAction = null,
}: Props<T>) => {
  const { shouldShowAuditingWarning } = useOrderPermissions();
  const {
    navigationBlocked,
    setNavigationStoreValues,
    resetNavigationStoreValues,
  } = useNavigationBlockerStore();

  const [showAccountingWarning, setShowAccountingWarning] = useState(false);
  const [showMainDialog, setShowMainDialog] = useState(true);

  useEffect(() => {
    setNavigationStoreValues({
      navigationBlocked: shouldBlock && showMainDialog,
    });
  }, [shouldBlock, setNavigationStoreValues, showMainDialog]);

  const handleSubmit = (cb?: () => void) => {
    if (!handleConfirm) return;
    if (!formMethods) {
      return handleConfirm({} as any);
    }
    return formMethods.handleSubmit(
      (data) => handleConfirm(data, cb),
      handleError
    )();
  };

  const handleSaveClick = async () => {
    const isValid = formMethods ? await formMethods?.trigger() : true;
    if (!isValid) {
      return;
    }
    if (shouldShowAuditingWarning && withAuditWarning) {
      setShowMainDialog(false);
      setShowAccountingWarning(true);
    } else {
      handleSubmit();
    }
    resetNavigationStoreValues();
  };

  const handleCloseMainForm = () => {
    if (!handleClose) {
      return null;
    }
    const onLeaveCallback = () => {
      setShowMainDialog(false);
      handleClose();
    };
    if (navigationBlocked && showMainDialog) {
      setNavigationStoreValues({ onLeaveCallback });
    } else {
      onLeaveCallback();
    }
  };

  const contentLoaded = useContentLoaded();
  if (!contentLoaded) return null;

  return (
    <>
      <Dialog
        open={showMainDialog}
        sx={{ ...styles }}
        fullWidth={!styles}
        maxWidth={!styles ? maxWidth : undefined}>
        <ModalTitle
          title={title}
          handleClose={handleCloseMainForm}
          styles={text ? { pb: 0 } : undefined}
        />

        <DialogContent>
          {text ? <DialogContentText>{text}</DialogContentText> : null}
          {children}
        </DialogContent>

        {!withoutActions ? (
          <ModalActions
            handleClose={handleCloseMainForm}
            handleConfirm={handleSaveClick}
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            isLoadingConfirm={isLoadingConfirm}
            disabledConfirm={disabledConfirm}
            additionalAction={additionalAction}
          />
        ) : null}
      </Dialog>

      {showAccountingWarning && !showMainDialog ? (
        <Suspense fallback={<FullScreenLoader />}>
          <AccountingStatusChangeConfirmation
            handleClose={() => {
              setShowAccountingWarning(false);
              handleClose?.();
            }}
            handleConfirm={handleSubmit}
            isLoading={isLoadingConfirm}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default BasicModal;
