export enum AppRoute {
  Auth = "auth",
  Dashboard = "dashboard",
  Orders = "orders",
  Search = "orders/search",
  OrderDetails = "orders/:orderId",
  AllOrders = "all",
  BatchUploaded = "batch_uploaded",
  BatchOrders = "orders/batch_uploaded/:batchId",
  CreateOrderSingle = "orders/new/single",
  CreateOrderBatch = "orders/new/batch",
  Reports = "reports",
  ReportDetails = "reports/:reportType",
  Reminders = "reminders",
  Carriers = "carriers",
  Users = "users",
  UserManagement = "user_management",
  ServiceCatalog = "service_catalog",
  RatePlanManagement = "rate_plan_management",
  ClientManagement = "client_management",
  TestingTools = "testing-tools",
  BillingBatches = "billing/receivables",
  BillingBatchDetails = "billing/receivables/:batchId",
  PaymentBatches = "billing/payables",
  PaymentBatchDetails = "billing/payables/:batchId",
  SupportingDocs = "supporting_docs",
  MappingManagement = "mapping_management",
  BatchOrderMapping = "batch_order",
  BillingExport = "billing_export",
  DSPs = "dsps",
  HubLocations = "hub_locations",
  SLARules = "sla_settings",
}
