import { OrderStatus } from "@enums/BE/OrderStatus";
import { useActiveOrderStore } from "@store/useActiveOrderStore";
import { useUserRole } from "./useUserRole";

export const useOrderPermissions = () => {
  const { isAdminOrBilling } = useUserRole();

  const { order } = useActiveOrderStore();

  const isAuditedOrder = order?.status
    ? order.status === OrderStatus.AuditPass ||
      order.status === OrderStatus.QBExported ||
      order.status === OrderStatus.QBFailed ||
      order.status === OrderStatus.QBInvProcessing ||
      order.status === OrderStatus.AutomatedBilling ||
      order.status === OrderStatus.ClientInvoiceGenerated ||
      order.status === OrderStatus.Completed
    : false;

  const shouldShowAuditingWarning = isAuditedOrder ? isAdminOrBilling : false;

  const isSentToRpsOrder = !!order?.rpsOrderUrl && !order.hasClaims;

  const canEditOrder = isSentToRpsOrder
    ? false
    : isAuditedOrder
    ? isAdminOrBilling
    : true;

  return {
    canEditOrder,
    isAuditedOrder,
    shouldShowAuditingWarning,
    isSentToRpsOrder,
  };
};
