import { useMsal } from "@azure/msal-react";
import FullScreenLoader from "@components/FullScreenLoader";
import { DynamicConfig, globalConfig } from "@configuration";
import { AppRoute } from "@enums/FE/AppRoute";
import { filterRoutes } from "@helpers/filterRoutes";
import { Suspense } from "react-lazy-no-flicker";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./layout";

const getRouter = (config: DynamicConfig) => {
  const filteredRoutes = filterRoutes({ config });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {filteredRoutes
          .filter((route) => route.public)
          .map(({ path, name, element }) => (
            <Route
              key={path || name}
              path={path}
              element={
                <Suspense fallback={<FullScreenLoader />}>{element}</Suspense>
              }
            />
          ))}
        {filteredRoutes
          .filter((route) => !route.public)
          .map(({ path, name, element, nestedRoutes }) => (
            <Route
              key={path || name}
              path={path}
              element={
                <Layout>
                  <Suspense fallback={<FullScreenLoader />}>
                    {element || <></>}
                  </Suspense>
                </Layout>
              }>
              <>
                {!!nestedRoutes &&
                  nestedRoutes.map((nested) => (
                    <Route
                      key={nested.path}
                      path={nested.path}
                      element={
                        <Layout>
                          <Suspense fallback={<FullScreenLoader />}>
                            {nested.element}
                          </Suspense>
                        </Layout>
                      }
                    />
                  ))}
              </>
            </Route>
          ))}
        <Route path="*" element={<Navigate to={AppRoute.Dashboard} />} />
      </>
    )
  );

  return router;
};

const Router = () => {
  const { inProgress } = useMsal();
  const config = globalConfig.get();

  return (
    <>
      {inProgress === "none" ? (
        <RouterProvider router={getRouter(config)} />
      ) : (
        <FullScreenLoader />
      )}
    </>
  );
};

export default Router;
