import { AppRoute } from "@enums/FE/AppRoute";
import { QueryKey } from "@enums/FE/QueryKey";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { UserDto } from "@services/fms-api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

type Variables = {
  active?: boolean | null;
  enabled?: boolean;
};

const getUsers = async (variables?: Variables): Promise<UserDto[]> => {
  const { active = true } = variables || {};

  let url = "users";
  if (active !== null) {
    url += `?active=${active}`;
  }
  const result = await axios.get(url);
  return result?.data;
};

export const useFetchUsers = (variables?: Variables) => {
  const { active = true, enabled = true } = variables || {};

  const navigate = useNavigate();
  const { showSnackbarMessage } = useSnackbarMessage();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.Users, active],
    queryFn: async () => {
      const result = await getUsers({ active });
      // @ts-ignore
      if (result?.response?.status === 403) {
        showSnackbarMessage("User is not in the list of active users", "error");
        localStorage.clear();
        navigate(`/${AppRoute.Auth}`);
      } else {
        return result;
      }
    },
    enabled,
    staleTime: 60 * 60 * 1000, // 60 minutes
    gcTime: 65 * 60 * 1000, // 65 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const validUsers = useMemo(
    () =>
      data
        ? data
            .filter(({ email }) => !!email)
            .sort((a, b) => ((a?.email || "") > (b?.email || "") ? 1 : -1))
        : undefined,
    [data]
  );

  const userOptions = useMemo(
    () =>
      validUsers && active
        ? validUsers.map(({ id, firstName, lastName, email, role }) => ({
            value: id || 0,
            label:
              firstName && lastName
                ? `${firstName?.trim() || ""} ${lastName?.trim() || ""}`
                : "",
            email: email?.trim() || "",
            roleId: role?.id || 0,
            roleName: role?.name || "",
          }))
        : undefined,
    [validUsers, active]
  );

  return { users: validUsers, userOptions, isFetchingUsers: isLoading };
};
