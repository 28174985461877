import { GetSingleOrderDto } from "@services/fms-api";
import { create } from "zustand";

interface ActiveOrderStore {
  order?: GetSingleOrderDto;
  setOrder: (activeOrder?: GetSingleOrderDto) => void;
}

export const useActiveOrderStore = create<ActiveOrderStore>((set) => ({
  order: undefined,
  setOrder: (order) => set(() => ({ order })),
}));
