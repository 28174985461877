import {
  alpha,
  Components,
  createTheme,
  PaletteOptions,
  responsiveFontSizes,
  Theme,
} from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

const palette: PaletteOptions = {
  primary: {
    light: "#77AF00", // green
    main: "#46A33B",
    contrastText: "#fff",
  },
  secondary: {
    main: "#1B1C18", // black
    dark: "#76786D",
  },
  error: {
    main: "#d32f2f",
    dark: "#BA1A1A",
  },
  info: {
    main: "#0080E0",
  },
};

const typography: TypographyOptions = {
  h5: {
    fontSize: "1.75rem",
    lineHeight: 2,
  }, // page title
  caption: {
    fontSize: "0.7rem",
  },
  subtitle1: {
    color: "#76786D",
    lineHeight: 1.25,
    fontSize: "0.875rem",
  }, // fontWeight: 400
  subtitle2: {
    color: "#76786D",
    fontSize: "0.75rem",
    lineHeight: 1,
  }, // fontWeight: 500
};

const components: Components<Omit<Theme, "components">> = {
  MuiDrawer: {
    styleOverrides: {
      paper: ({ theme }) => ({
        background: theme.palette.secondary.main,
        color: alpha(theme.palette.common.white, 0.8),
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiInputBase-root": {
          backgroundColor: theme.palette.common.white,
        },
        "& .MuiFormHelperText-root.Mui-error": {
          color: theme.palette.error.main,
          fontSize: "0.75rem",
        },
      }),
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiDialog: {
    defaultProps: {
      maxWidth: "xl",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        marginTop: `0 !important`,
        zIndex: theme.zIndex.drawer + 100,
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        "&.MuiDialogContent-root": {
          paddingTop: 12,
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: { "& .MuiToolbar-root": { paddingLeft: 16 } },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "rgba(99, 129, 36, 0.16)",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.color === "primary" && ownerState.variant === "contained"
          ? {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
            }
          : ownerState.variant === "outlined"
          ? {
              "& p": {
                color: theme.palette.text.primary,
              },
              "&.Mui-disabled p": {
                color: theme.palette.text.disabled,
              },
            }
          : undefined),
        minWidth: "7.5rem !important",
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: { marginTop: 0 },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: { "&.MuiFormControlLabel-root": { margin: 0 } },
    },
  },
};

let theme = createTheme({
  palette,
  typography,
  components,
});

theme = responsiveFontSizes(theme);

export default theme;
