import { useWindowSize } from "@hooks/customHooks/useWindowSize";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import MenuTwoTone from "@mui/icons-material/MenuTwoTone";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useSideMenuStore } from "@store/useSideMenuStore";
import CreateButtons from "./CreateButtons";
import Logo from "./Logo";
import SearchBox from "./SearchBox";
import TimeByZones from "./TimeByZones";
import User from "./User";

const Header = () => {
  const { sideMenuOpen, setSideMenuOpen } = useSideMenuStore();
  const { width } = useWindowSize();

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: ({ zIndex }) => zIndex.drawer + 1 }}
      color="secondary">
      <Toolbar
        disableGutters
        sx={{
          flexDirection: (width || 0) < 1700 ? "column" : "row",
          minWidth: "1200px",
        }}>
        {(width || 0) < 1700 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TimeByZones />
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: (width || 0) > 1700 ? 0 : "0.25rem",
          }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              gap: "1rem",
            }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setSideMenuOpen(!sideMenuOpen)}>
              {!sideMenuOpen ? <MenuTwoTone /> : <CloseTwoTone />}
            </IconButton>
            <Logo />
            <SearchBox />
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            {(width || 0) > 1700 ? <TimeByZones /> : null}

            <CreateButtons />

            <User />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
