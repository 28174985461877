import { useCallback } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";
import BasicModal from "./BasicModal";

interface NavigationBlockerModalProps {
  navigationBlocked: boolean;
  open: boolean;
  title?: string;
  onChangeBlockStatus: (status: boolean) => void;
  onLeave?: () => void;
  onStay: () => void;
}

const NavigationBlockerModal = ({
  navigationBlocked,
  open,
  title,
  onChangeBlockStatus,
  onLeave,
  onStay,
}: NavigationBlockerModalProps) => {
  const blocker = useBlocker(onLocationChange);

  useBeforeUnload(
    (event) => {
      if (navigationBlocked) {
        event.preventDefault();
        event.returnValue = null;
      }
    },
    { capture: true }
  );

  const onSubmitLeave = useCallback(() => {
    if (onLeave) {
      onLeave();
    }

    if (blocker.proceed) {
      blocker.proceed();
    }
  }, [blocker, onLeave]);

  const onSubmitStay = useCallback(() => {
    onStay();

    if (blocker.reset) {
      blocker.reset();
    }
  }, [blocker, onStay]);

  function onLocationChange() {
    onChangeBlockStatus(navigationBlocked);

    return navigationBlocked;
  }

  return (
    <>
      {open ? (
        <BasicModal
          handleClose={onSubmitStay}
          handleConfirm={onSubmitLeave}
          cancelBtnText="Stay"
          confirmBtnText="Leave"
          title={title || "Unsaved Changes"}
          text="You have unsaved changes. Are you sure you want to leave?"
        />
      ) : null}
    </>
  );
};

export default NavigationBlockerModal;

export type { NavigationBlockerModalProps };
